import API from "../api";

export const insertUserFile = async(file: any, user_address: string, type: string) => {
    try {
        console.log(file)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userAddress', user_address);
        formData.append('document_name', file.name);
        formData.append('type', type);
        // Enviar o FormData com uma requisição POST
        let req = await API.post(`/api/storage/ipfs/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return req.data;
    } catch (err) {
        console.log(err);
    }
}

export const getUserFiles = async(userId: number) => {
    try {
        let req = await API.get(`/api/storage/ipfs/files/${userId}`)
        if(req) {
            return req.data
        }else {
            return {}
        }
    }catch (err) {
        console.log(err)
    }
}
