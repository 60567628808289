import React, { useState } from 'react'
import KineContext from './KineContext'
import { User } from '../interface'
import { ModerationService } from '../service/moderation/moderationService'
import { api } from '../service/substrate/substrate'
import { getSocialProfileByAddres } from '../service/social/socialService'
import { NotificationService } from '../service/notification/notificationService'
import Cookies from 'js-cookie'
const defaultColors = {
	action: '#d090dc',
	info: '#d090dc',
	text: '#292929',
	alert: '#f1f664ed',
	alertText: '#000',
	blackColor: '#0000',
	sidenav: '#8f559a',
	bg: '#e6b8f0',
	asideText: '#fff',
	title: '#292929',
	logo: '#fff',
}

interface INotification {
	content: string
	id: number
	status: number
	type: string
	user_id: number
}

const optionsLanguage = [
	{ label: 'English', value: 'en' },
	{ label: 'Espanhol', value: 'es' },
	{ label: 'French', value: 'fr' },
]

export const KineProvider = ({ children }: any) => {
	const [user, setUser] = useState({} as any)

	const [globalToast, setGlobalToast] = useState<boolean>(false)
	const [onClicked, setOnclicked] = useState<any>(0)
	const [apiState, setApiState] = useState<any>(null)
	const [isModerator, setIsModerator] = useState(false)
	const [profile, setGlobalProfile] = useState<any>(null)
  	const [verifyCurrentUserStorage, setCurrentUserStorage] = useState<boolean>(false)
	const [globalSnack, setGlobalSnack] = useState(false)
	const [openHelp, setOpenHelp] = useState(false)
	const [currentTab, setCurrentTab] = useState('')
	const [showFullMenu, setShowFullMenu] = useState(true)
	const [notifications, setNotifications] = useState<INotification[]>([])
	const [currentLangues, setCurrentLanguage] = useState(optionsLanguage[0])
	const [storageUsed, setStorageUsed] = React.useState<string>('')
	const [onQueue, setOnQueue] = React.useState(false)
	const [globalMessage, setGlobalMessage] = useState('Waiting for transaction to complete.')

	let userData = Cookies.get('kinera-cookie')

	const handleSetGlobalFetch = () => {
		getUserProfile()
	}

	const handleGlobalMessage = (param:string) :void => {
		setGlobalMessage(param)
		let userData = Cookies.get('kinera-cookie')
		if (userData && param.includes('error')) {
			//Cookies.remove('kinera-cookie')
		}
	}

	const getRecoveryUser = async () => {
		if (userData) {
			await getUserProfile()
		} 
	}

	const verifyUserIsModerator = async () => {
		let req: any = await ModerationService.verifyModerator(user, apiState)
		if (req && req.rank) {
			setIsModerator(true)
		} else {
			setIsModerator(false)
		}
	}

	const getInitialColor = () => {
		/**const savedColor = localStorage.getItem('kinera__currentColor') */
		return defaultColors
	}

	const [color, setColor] = useState(getInitialColor())

	const connect = async () => {
		let con = await api()
		setApiState(con)
	}

	const getUserProfile = async () => {
		setGlobalToast(true)
		if (userData) {
			const parsedUser = JSON.parse(userData)
			let myProfile = await getSocialProfileByAddres(parsedUser.address)

			if (myProfile) {
				setGlobalProfile(myProfile)

				setUser(myProfile)
				setGlobalToast(false)
			} else {
				setGlobalToast(false)
			}
		}
	}

	const getAllNotifyUser = async () => {
		if (profile && profile.id) {
			let req = await NotificationService.getAllNotify(profile.id)
			if (req && req.data) setNotifications(req.data)
		}
	}

	React.useEffect(() => {
		if (!apiState) {
			connect()
		}
		getRecoveryUser()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData, storageUsed])

	const swapCurrentHelp = (value: boolean, param: string) => {
		setOpenHelp(value)
		setCurrentTab(param)
	}

	return (
		<KineContext.Provider
			value={{
				user,
				setUser,
				handleSetGlobalFetch,
				setIsModerator,
				isModerator,
				color,
				setColor,
				apiState,
				onClicked,
				setOnclicked,
				globalToast,
				setGlobalToast,
				profile,
				setGlobalProfile,
				globalSnack,
				setGlobalSnack,
				openHelp,
				swapCurrentHelp,
				showFullMenu,
				setShowFullMenu,
				notifications,
				setNotifications,
				getAllNotifyUser,
				optionsLanguage,
				currentLangues,
				setCurrentLanguage,
				currentTab,
				verifyCurrentUserStorage,
				setCurrentUserStorage,
				storageUsed,
				setStorageUsed,
				onQueue,
				setOnQueue,
				globalMessage,
				handleGlobalMessage
			}}>
			{children}
		</KineContext.Provider>
	)
}
