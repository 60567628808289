import React from 'react'
import { IKineraPost } from '../../interface'
import { Link, useNavigate } from 'react-router-dom'
import {
	commentOnPost,
	dislike,
	followAsync,
	getAllPosts,
	getCommentsOnPost,
	postLike,
	removePost,
	removeSave,
	repost,
	savePost,
	unfollowAsync,
} from 'src/service/social/socialService'
import { User } from 'src/interface'
import KineContext from 'src/context/KineContext'
import RichTextDisplay from '../../../createTweet/RichText/Display'
import CommentBox from '../CommentBox/CommentBox'
import { AiOutlineComment } from 'react-icons/ai'
import { Loading } from 'src/components/loading'
import Tooltip from '@mui/material/Tooltip'
import ImagesDisplay from '../ImagesDisplay'
import DscIcons from 'src/components/_Dsc/icons'
import RichTextEditor from '../../../createTweet/RichText/RichText'
import Login from 'src/pages/login/Login'
import HorizontalDivider from 'src/components/_Dsc/divider'
import '../../style.scss'
import Button from 'src/components/_Dsc/Button'
import Dropdown from 'src/components/_Dsc/Dropdown'
import Modal from 'src/components/_Dsc/Modal'
import InputText from 'src/components/_Dsc/InputText'
import Feed from '../../Feed'
import ConstellationPost from './components/ConstellationPost'
import WriteComment from './components/WriteComment'
import PostActions from './components/PostActions'
import { TextStyleBodyDefaultMedium, TextStyleCaption } from 'src/components/_Dsc/typography'
import InitialSimbol from 'src/components/_Dsc/InitialSimbol'

const defaultImg: string =
	'https://media.istockphoto.com/id/522855255/vector/male-profile-flat-blue-simple-icon-with-long-shadow.jpg?s=612x612&w=0&k=20&c=EQa9pV1fZEGfGCW_aEK5X_Gyob8YuRcOYCYZeuBzztM='

const KineraPost: React.FC<IKineraPost> = ({
	avatarSrc,
	username,
	viewProfile,
	time,
	message,

	id,
	setIsWriteComment,
	isWriteComment,
	content,
	setContent,

	isProfile,
	address,

	is_festival,
	is_rankings,
	event_id,
	user_id,
	setData,
	tweet,
	disabledRepost,
}) => {
	const { user, apiState, setGlobalToast, profile, getAllNotifyUser, setOnQueue } = React.useContext(KineContext)

	const optionsYesNo = [
		{ value: 'post', label: 'View post' },
		{ value: 'profile', label: 'Profile Details' },
	]

	const [isComment, setIsComment] = React.useState(false)
	const [comments, setComments] = React.useState<any>([])

	const [hiddenInput, setHiddenInput] = React.useState(false)
	const [removeFetch, setRemoveFetch] = React.useState(false)

	const [isLogin, setIsLogin] = React.useState(false)
	const [openRepostModal, setOpenRepostModal] = React.useState(false)
	const [repostMessage, setRepostMessage] = React.useState('')
	const [isFollow, setIsFollow] = React.useState(tweet.is_follow)
	const [priority, setPriority] = React.useState(false)
	const [saved, setSaved] = React.useState(tweet.has_saved)
	const [localLikes, setLocalLikes] = React.useState(tweet.likes)
	const [localDislikes, setLocalDislikes] = React.useState(tweet.dislikes)
	const [hasUserLiked, setHasUserLiked] = React.useState(tweet.has_user_liked)
	const [hasUserUnliked, setHasUserUnliked] = React.useState(tweet.has_user_unliked)
	const navigate = useNavigate()

	const showCommentsOnPost = async (id: string, value?: any) => {
		if (value) {
			setContent(
				`<p><span style="color: #0053e4;"><a href="${`https://d-app.kinera.network/social/view/${value.address}`}" rel="noopener noreferrer" target="_blank">@${
					value.username
				}</a></span>&nbsp;&nbsp;&nbsp;&nbsp</span></p>`,
			)
		}
		setIsWriteComment(id)
	}

	const getCurrentComments = async (id: string) => {
		setIsWriteComment(id)

		const comments = await getCommentsOnPost(id, apiState)

		setComments(comments)
	}

	const commentOnPostById = async (id: string) => {
		setOnQueue(true)
		setIsComment(true)
		let req = await commentOnPost(id, content, user, setOnQueue, address)
		if (req) {
			if (req.data) comments.push(req.data)
			getAllNotifyUser()
		}
		setContent('')
		setIsComment(false)
		setOnQueue(false)
	}

	const newFormatedDate = () => {
		const date = new Date(time)
		const day = String(date.getDate()).padStart(2, '0')
		const month = String(date.getMonth() + 1).padStart(2, '0')
		const year = date.getFullYear()
		const hours = String(date.getHours()).padStart(2, '0')
		const minutes = String(date.getMinutes()).padStart(2, '0')

		return `${day}/${month}/${year} - ${hours}:${minutes}`
	}

	const removePostById = async (id: string) => {
		setRemoveFetch(true)
		setOnQueue(true)
		const payload = {
			user: profile.id.toString(),
		}
		await removePost(id, payload)
		setRemoveFetch(false)
		let req = await getAllPosts(false)
		setOnQueue(false)
		if (setData) setData(req)
	}

	const sendLikeToPost = async (id: string) => {
		setOnQueue(true)

		if (hasUserLiked) {
			setLocalLikes((prev: number) => prev - 1)
			setHasUserLiked(false)
			if (hasUserUnliked) {
				setLocalDislikes((prev: number) => prev - 1)
				setHasUserUnliked(false)
			}
		}
		if (!hasUserLiked) {
			setLocalLikes((prev: number) => prev + 1)
			setHasUserLiked(true)
		}

		await postLike(id, user.address)

		setOnQueue(false)
	}

	const sendDislikeToPost = async (id: string) => {
		setOnQueue(true)

		if (hasUserUnliked) {
			setLocalDislikes((prev: number) => prev + 1)
			setHasUserUnliked(false)
			if (hasUserLiked) {
				setLocalLikes((prev: number) => prev - 1)
				setHasUserLiked(false)
			}
		}
		if (!hasUserUnliked) {
			setLocalDislikes((prev: number) => prev - 1)
			setHasUserUnliked(true)
		}
		await dislike(id, user.address)
		setOnQueue(false)
	}

	const handleClickLoginButton = () => {
		const loginButton = document.getElementById('login-btn') as HTMLButtonElement | null
		if (loginButton) {
			loginButton.click()
		} else {
			console.error('Login button not found')
		}
	}

	const handleDropDownChange = (param: string, id: number, address: string) => {
		if (param === 'profile') {
			navigate('/social/view/' + address)
		} else if (param === 'post') {
			navigate('/social/post/' + id)
		}
	}

	const unfollow = async (id: string, follow: User) => {
		setIsFollow(false)
		await unfollowAsync(id, follow?.address)
	}

	const follow = async (id: string, follow: User) => {
		setIsFollow(true)
		await followAsync(id, follow?.address)
	}

	const userSavePost = async (tweet: any) => {
		if (user && user.address) {
			if (!saved) {
				setOnQueue(true)
				setSaved(true)
				await savePost(tweet.id, user.address)
				setOnQueue(false)
			}
			if (saved) {
				setOnQueue(true)
				setSaved(false)
				await removeSave(tweet.id, user.address)
				setOnQueue(false)
			}
		}
	}

	const removeHtmlTags = (str: string) => {
		const tempDiv = document.createElement('div')
		tempDiv.innerHTML = str
		return tempDiv.textContent || tempDiv.innerText || ''
	}

	const shareMessage = (param: string, message: string) => {
		console.log('param', param)
		const cleanMessage = removeHtmlTags(message)
		const encodedMessage = encodeURIComponent(cleanMessage)

		if (param === 'x') {
			const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}`
			window.open(twitterUrl, '_blank')
		} else if (param === 'telegram') {
			const telegramUrl = `https://t.me/share/url?url=${encodedMessage}`
			window.open(telegramUrl, '_blank')
		}

		// URL para compartilhar no Twitter

		// URL para compartilhar no Telegram

		// Abrir uma nova janela com as opções de compartilhamento
		//window.open(twitterUrl, '_blank')
		//window.open(telegramUrl, '_blank')
	}

	const handleOpenRepostModal = (tweet: IKineraPost) => {
		if (!disabledRepost && !tweet.is_repost) {
			//console.log('tweet', tweet)
			setOpenRepostModal(true)
		}
	}

	const handleCloseRepostModal = () => {
		setOpenRepostModal(false)
	}

	const onChangeWriteMessage = (param: string) => {
		console.log('message', param)
		setRepostMessage(param)
	}

	const handlePost = async () => {
		setOnQueue(true)
		handleCloseRepostModal()
		await repost(tweet, repostMessage, user.address)

		setOnQueue(false)
	}

	return (
		<div>
			{openRepostModal && (
				<Modal
					onCancel={handleCloseRepostModal}
					onClose={handleCloseRepostModal}
					onConfirm={handlePost}
					confirmLabel="Repost"
					open={openRepostModal}
					//confirmDisabled={!repostMessage || !repostMessage.length}
					cancelLabel="Cancel"
					title="Repost"
					size="medium">
					<div className="flex items-center gap-2 flex-col w-full">
						<InputText
							label="Message"
							placeholder="write your message for repost"
							onChange={onChangeWriteMessage}
							value={repostMessage}
						/>
						<div className="w-full">
							<KineraPost
								viewProfile={viewProfile}
								avatarSrc={tweet.avatarSrc}
								username={tweet.username}
								time={tweet.time}
								message={tweet.message}
								id={tweet.id}
								setIsWriteComment={() => {}}
								isWriteComment={false}
								content={content}
								setContent={() => {}}
								isProfile={true}
								address={tweet.address}
								is_festival={tweet.is_festival}
								is_rankings={tweet.is_rankings}
								event_id={tweet.event_id}
								user_id={tweet.user_id}
								setData={setData}
								tweet={tweet}
								disabledRepost={true}
							/>
						</div>
					</div>
				</Modal>
			)}
			<Login isLogin={isLogin} setIsLogin={setIsLogin} />
			{is_festival === 0 ? (
				<div
					className={
						priority
							? 'flex flex-col items-start w-full flex-col drop-shadow-md  rounded-[4px]  home-container z-[9999999999]'
							: 'flex flex-col items-start w-full flex-col drop-shadow-md  rounded-[4px]  home-container'
					}>
					<div className="w-full flex gap-5 items-start ">
						<div
							onClick={() => {
								navigate('/social/view/' + tweet.address)
							}}>
							<InitialSimbol
								size="lg"
								profile={{
									img: avatarSrc,
									name: username,
								}}
							/>
						</div>
						<div className="w-full rounded-[8px] bg-white p-3">
							{tweet.is_repost ? (
								<div className="flex flex-col gap-2">
									<div className="flex  flex-col items-start">
										<div
											className="w-full flex flex-col hover:opacity-80"
											onClick={() => {
												navigate('/social/view/' + tweet.address)
											}}>
											<Link to={'/social/view/' + tweet.address} className="truncate">
												{username}
											</Link>
										</div>
										<div className="flex items-center gap-2">
											<TextStyleBodyDefaultMedium>{tweet.repost_message}</TextStyleBodyDefaultMedium>
										</div>
									</div>
									<div className="w-full flex gap-5 items-start is-repost">
										<div
											onClick={() => {
												navigate('/social/view/' + tweet.user_owner.address)
											}}>
											<InitialSimbol
												size="lg"
												profile={{
													img: tweet.user_owner_image,
													name: tweet.user_owner_name,
												}}
											/>
										</div>

										<div className="w-full">
											<div className="flex flex-col rounded-[4px] shadow-default w-full">
												<div className="flex items-start border-[#efefef] gap-[10px] cursor-pointer">
													<div className="w-full h-full">
														<div className="flex justify-between items-center">
															<div
																className="w-full flex flex-col hover:opacity-80"
																onClick={() => {
																	navigate('/social/view/' + tweet.user_owner.address)
																}}>
																<Link to={'/social/view/' + tweet.user_owner.address} className="truncate">
																	{tweet.user_owner_name}
																</Link>
															</div>
															<div className="flex items-center gap-2">
																<div
																	className="flex items-center justify-end gap-2"
																	onClick={() => {
																		setPriority(true)
																	}}>
																	<span className="truncate max-w-[300px] text-[12px]">{newFormatedDate()}</span>
																	<div className="w-fit">
																		<Dropdown
																			setPriority={setPriority}
																			icon="more_vert"
																			options={optionsYesNo}
																			value={['']}
																			onChange={(e: any) => {
																				handleDropDownChange(e[0], tweet.post_id, tweet.user_owner.address)
																			}}></Dropdown>
																	</div>
																</div>
															</div>
														</div>
														<div className="cursor-pointer">
															<RichTextDisplay initialValue={message} readOnly={true} />

															{tweet.urls && tweet.urls.length >= 1 && tweet.urls[0] !== '' && (
																<ImagesDisplay urls={tweet.urls} />
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="flex items-center w-full justify-between">
										<div className="flex items-center ">
											<PostActions
												userSavePost={userSavePost}
												saved={saved}
												tweet={tweet}
												sendLikeToPost={sendLikeToPost}
												shareMessage={shareMessage}
												setPriority={setPriority}
												handleOpenRepostModal={handleOpenRepostModal}
												showCommentsOnPost={showCommentsOnPost}
												setIsWriteComment={setIsWriteComment}
												hasUserLiked={hasUserLiked}
												localLikes={localLikes}
												sendDislikeToPost={sendDislikeToPost}
												hasUserUnliked={hasUserUnliked}
												localDislikes={localDislikes}
												handleClickLoginButton={handleClickLoginButton}
												isWriteComment={isWriteComment}
												message={message}
												id={id}
												user_id={user_id}
												removeFetch={removeFetch}
												removePostById={removePostById}
											/>

											<>
												{user && user.address && (
													<>
														{(isWriteComment !== id && !user.address) ||
															(!isProfile && (
																<div className="flex flex-col gap-2 items-start justify-center">
																	<button
																		className="secondary-blue p-2 whitespace-nowrap text-white"
																		onClick={() => {
																			showCommentsOnPost(id)
																		}}>
																		<AiOutlineComment size={30} />
																	</button>
																	<p className="write-text flex items-center hover:bg-pink-100 hover:font-bold p-2 rounded-lg cursor-pointer">
																		To comment Connect your wallet and create a social profile
																	</p>
																</div>
															))}
													</>
												)}
											</>
										</div>
										<TextStyleCaption>Repost by: {tweet.username}</TextStyleCaption>
									</div>
								</div>
							) : (
								<div>
									<div className="flex flex-col rounded-[4px] shadow-default w-full">
										<div className="flex items-start border-[#efefef] gap-[10px] cursor-pointer">
											<div className="w-full h-full">
												<div className="flex justify-between items-center">
													<div
														className="w-full flex flex-col hover:opacity-80"
														onClick={() => {
															navigate('/social/view/' + tweet.address)
														}}>
														<Link to={'/social/view/' + address} className="truncate">
															{username}
														</Link>
													</div>
													<div className="flex items-center gap-2">
														{/**
                           * profile && profile.user && user && tweet.address !== profile.user && !viewProfile && (
													<>
														{isFollow ? (
															<Button
																size="small"
																type="warning"
																value={'Unfollow'}
																onClick={() => unfollow(tweet.address, user)}
																disabled={!profile}
															/>
														) : (
															<Button
																size="small"
																value={'Follow'}
																onClick={() => follow(tweet.address, user)}
																disabled={!profile}
															/>
														)}
													</>
												)
                           */}
														<div
															className="flex items-center justify-end gap-2"
															onClick={() => {
																setPriority(true)
															}}>
															<span className="truncate max-w-[300px]  text-[12px]">{newFormatedDate()}</span>
															<div className="w-fit">
																<Dropdown
																	setPriority={setPriority}
																	icon="more_vert"
																	options={optionsYesNo}
																	value={['']}
																	onChange={(e: any) => {
																		handleDropDownChange(e[0], tweet.id, tweet.address)
																	}}></Dropdown>
															</div>
														</div>
													</div>
												</div>
												<div className="cursor-pointer">
													<RichTextDisplay initialValue={message} readOnly={true} />

													{tweet.urls && tweet.urls.length >= 1 && tweet.urls[0] !== '' && (
														<ImagesDisplay urls={tweet.urls} />
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="flex items-center ">
										<PostActions
											userSavePost={userSavePost}
											saved={saved}
											tweet={tweet}
											sendLikeToPost={sendLikeToPost}
											shareMessage={shareMessage}
											setPriority={setPriority}
											handleOpenRepostModal={handleOpenRepostModal}
											showCommentsOnPost={showCommentsOnPost}
											setIsWriteComment={setIsWriteComment}
											hasUserLiked={hasUserLiked}
											localLikes={localLikes}
											sendDislikeToPost={sendDislikeToPost}
											hasUserUnliked={hasUserUnliked}
											localDislikes={localDislikes}
											handleClickLoginButton={handleClickLoginButton}
											isWriteComment={isWriteComment}
											message={message}
											id={id}
											user_id={user_id}
											removeFetch={removeFetch}
											removePostById={removePostById}
										/>

										<>
											{user && user.address && (
												<>
													{(isWriteComment !== id && !user.address) ||
														(!isProfile && (
															<div className="flex flex-col gap-2 items-start justify-center">
																<button
																	className="secondary-blue p-2 whitespace-nowrap text-white"
																	onClick={() => {
																		showCommentsOnPost(id)
																	}}>
																	<AiOutlineComment size={30} />
																</button>
																<p className="write-text flex items-center hover:bg-pink-100 hover:font-bold p-2 rounded-lg cursor-pointer">
																	To comment Connect your wallet and create a social profile
																</p>
															</div>
														))}
												</>
											)}
										</>
									</div>
								</div>
							)}
						</div>
					</div>

					{isProfile && isWriteComment === id && !hiddenInput && user && (
						<WriteComment
							tweet={tweet}
							isProfile={isProfile}
							isComment={isComment}
							setContent={setContent}
							content={content}
							setIsWriteComment={isWriteComment}
							commentOnPostById={commentOnPostById}
							id={id}
						/>
					)}
					<div className="w-full flex flex-col justify-end items-end">
						{isWriteComment === id && (
							<div className="flex flex-col max-h-[900px] overflow-y-auto w-[90%] pl-4">
								{comments && comments.length > 0 && (
									<CommentBox
										username={username}
										comments={comments}
										id={id}
										hiddenInput={hiddenInput}
										setOnAnswer={setHiddenInput}
										getCurrentComments={getCurrentComments}
									/>
								)}
							</div>
						)}
					</div>
				</div>
			) : (
				<ConstellationPost tweet={tweet} />
			)}
		</div>
	)
}

export default KineraPost
