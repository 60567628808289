import { link } from 'fs'

export const AsideMenuOptions = [
	{
		title: 'Rankings & Constellations',
		isLogin: true,
		childrens: [
			{
				label: 'My Constellations',
				icon: 'background_replace',
				haveOptions: false,
				link: 'my-constellations',
				selected: 'my-constellations',
			},
			{
				label: 'Claim Tokens',
				icon: 'editor_choice',
				haveOptions: false,
				link: 'reward',
				selected: 'reward',
			},
			{
				label: 'Voted Rankings & Constellations',
				icon: 'how_to_vote',
				haveOptions: false,
				soon: false,
				link: 'my-voteds',
				selected: 'my-voteds',
			},
		],
	},
	{
		title: 'Social',
		isLogin: true,
		childrens: [
			{
				label: 'Public Profile',
				icon: 'account_box',
				haveOptions: false,
				link: 'social/view',
			},
			{
				label: 'My Communities',
				icon: 'communities',
				haveOptions: false,
				soon: false,
				link: 'communitites/my-communities',
				selected: 'communitites/my-communities',
			},

			{
				label: 'Followers & Followings',
				icon: 'favorite',
				haveOptions: false,
			},
			{
				label: 'Favorites',
				icon: 'bookmark_heart',
				haveOptions: false,
				soon: false,
				link: 'favorites',
				selected: 'favorites',
			},
		],
	},
	{
		title: 'Tools',
		isLogin: true,
		childrens: [
			{
				label: 'My documents',
				icon: 'folder_open',
				haveOptions: false,
				soon: false,
				link: 'my-documents',
				selected: 'my-documents',
			},
			{
				label: 'Produtions Tools',
				icon: 'home_repair_service',
				haveOptions: false,
				link: 'custom-tools',
			},
			{
				icon: 'share_reviews',
				label: "GPT's",
				haveOptions: false,
				link: 'custom-tools',
			},
		],
	},
	{
		title: 'Help',
		childrens: [
			{
				label: 'Where to Start',
				icon: 'flag_circle',
				haveOptions: false,
				isHelp: true,
				destiny: 'Initial',
			},
			{
				label: 'What Does Kinera Pre-Alpha1.0 Have to Offer',
				icon: 'help',
				haveOptions: false,
				isHelp: true,
				destiny: 'About',
			},
		],
	},
]

export const optionsKinergy: any = [
	{
		name: 'My Festivals & rankings',
		id: 'mrk',
	},
	{
		name: 'Create festival',
		id: 'create',
	},
	{
		name: 'Upload movie',
		id: 'upload',
	},
	{
		name: 'Create categorie',
		id: 'tag',
	},
]

export const optionsSocial = [
	{
		name: 'Social Profile',
		id: 'flw',
	},

	/*
   {
    name: 'Create space',
    id: 'create',
  },
  {
    name: 'Posts',
    id: 'pst',
  },
  {
    name: 'Spaces',
    id: 'spc',
  },
 */
]
