import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

import KineContext from '../../context/KineContext'

import InitialHelp from './components/Initial'

import './style.scss'
import About from './components/About'
import Community from './components/Community'
import Button from 'src/components/_Dsc/Button'

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	height: '80%',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	borderRadius: '4px',
	boxShadow: 24,
	p: 4,
	overflowY: 'auto',
}

interface OptionalProps {
	saltTab?: string
}

const ModalHelp: React.FC<OptionalProps> = ({ saltTab }) => {
	const { openHelp, swapCurrentHelp, currentTab } = React.useContext(KineContext)
	const [tab, setCurrentTab] = React.useState('')
	const setHelpStatus = () => {
		localStorage.setItem('kinera__help', String(true))
	}
	const closeModal = () => {
		swapCurrentHelp(false, '')
	}
	const afterClose = () => {
		setHelpStatus()
		swapCurrentHelp(false, '')
	}
	React.useEffect(() => {
		setCurrentTab(currentTab)
	}, [currentTab])
	return (
		<div>
			<Modal keepMounted open={openHelp} onClose={closeModal}>
				<Box sx={style}>
					{/**	<HelpTabs value={tab} setValue={currentTab} /> */}
					<div className="w-full h-[90%] justify-between flex flex-col help-component">
						{tab === 'Initial' && (
							<div className="w-full  flex items-center justify-center">
								<InitialHelp currentTab={currentTab} />
							</div>
						)}
						{tab === 'About' && (
							<div className="w-full  flex items-center justify-center mt-2">
								{' '}
								<About />
							</div>
						)}
						<div className="w-full w-full">{tab === 'Community' && <Community />}</div>
						<div className="w-full flex items-center justify-end gap-5 pb-4">
							<Button
								size="small"
								onClick={() => {
									afterClose()
								}}
								value="Close"></Button>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}

export default ModalHelp
