import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InitialSimbol from 'src/components/_Dsc/InitialSimbol'
import { TextStyleBodyDefault, TextStyleBodyDefaultMedium } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import { communityService } from 'src/service/community/communityService'

interface Props {
	community_id: string
}

interface FileCommunity {
	id: string
	user_id: string
	html: string
	title: string
	owner_name: string
	description: string
	privacity: string
	is_community: string
	community_id: string
	created_at: string
	updated_at: string
}

export const CommunityFiles: React.FC<Props> = ({ community_id }) => {
	const navigate = useNavigate()
	const [data, setData] = useState([])
	const [inFetch, setInFetch] = useState<boolean>(true)

	const getData = async () => {
		setInFetch(true)
		let req = await communityService.getCommunityDocuments(community_id)
		if (req && req.data) {
			setData(req.data)
		}
		setInFetch(false)
	}

	const redirectToDocument = (id: string) => {
		window.open(`https://shared-doc.kinera.network/document/${id}`)
	}

	React.useEffect(() => {
		getData()
	}, [])

	return (
		<div className="bg-white p-4 flex flex-col gap-2">
			{inFetch ? (
				<Loading />
			) : data && data.length ? (
				data.map((val: FileCommunity, key: number) => (
					<div
						key={key}
						onClick={() => {
							redirectToDocument(val.id)
						}}
						className="hover:bg-gray-200  cursor-pointer bg-white rounded-[4px] border border-[#ededed] p-2 flex items-center justify-between gap-2">
						<TextStyleBodyDefault>Document: {val.title}</TextStyleBodyDefault>

						<div className="flex items-center gap-2">
							<TextStyleBodyDefault>{val.owner_name}</TextStyleBodyDefault>
							<InitialSimbol profile={{ img: undefined, name: val.owner_name }} />
						</div>
					</div>
				))
			) : (
				<TextStyleBodyDefaultMedium>There are no files in this community at the moment</TextStyleBodyDefaultMedium>
			)}
		</div>
	)
}
