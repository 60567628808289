import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import KineContext from '../../context/KineContext'
import Tooltip from '@mui/material/Tooltip'
import './style.scss'
import UserBalance from '../../components/balances'
import ModalFollowersAndFollowing from '../../pages/socialView/modal'
import { AsideMenuOptions } from 'src/constants/dic'
import Option from './components/Option'
import { getFolderSizeInKB } from 'src/service/storage/firebase'
import { TextStyleCaption } from 'src/components/_Dsc/typography'

const Aside: React.FC = props => {
	const {
		user,
		isModerator,
		verifyCurrentUserStorage,
		profile,
		swapCurrentHelp,
		showFullMenu,
		setShowFullMenu,
		currentLangues,
		setStorageUsed,
		storageUsed,
	} = React.useContext(KineContext)
	const [openSocial, setOpenSocial] = React.useState(false)
	const [currentTab, setCurrentTab] = React.useState('followers')
	const [isOpenDropdown, setIsOpenDropdown] = React.useState(false)
	
	const navigate = useNavigate()

	const handleChange = (tab: string) => {
		//setShowFullMenu(false)
		setOpenSocial(true)
		setCurrentTab(tab)
	}

	const redirectToLink = (children: any) => {
		if (!children.haveOptions && !children.isHelp) {
			if (children.link && children.label !== 'Public Profile' && children.label !== 'Followers & Followings') {
				navigate(children.link)
			} else if (user && user.address && children.label === 'Public Profile') {
				navigate(children.link + '/' + user.address)
			} else if (user && user.address && children.label === 'Followers & Followings') {
				handleChange('followers')
			}
		} else {
			if (children.isHelp) {
				swapCurrentHelp(true, children.destiny)
			} else {
				handleDropdownChange(children)
			}
		}
	}

	const handleDropdownChange = (children: any) => {
		if (children.options) {
			setIsOpenDropdown(!isOpenDropdown)
		}
	}

	const verifyIsSelected = (children: any) => {
		let param = ''
		if (children.selected && window.location.pathname) {
			if (window.location.pathname === `/${children.selected}`) {
				param = 'is-selected-option'
			}
		} else {
			param = ''
		}
		return param
	}

	
	const nagiteToSettings = () => {
		navigate('/config')
	}


	return (
		<div className="  z-[10] h-webkit top-15 fixed">
			<aside
				style={{
					width: showFullMenu ? '270px' : '80px',
				}}
				className={`bg-white right-divider h-full  top-0 left-0 z-[9] p-4  flex flex-col items-center justify-between`}>
				<div>
					{AsideMenuOptions.map((val: any, key: number) => (
						<div key={key} className="p-[8px] w-full flex flex-col gap-2">
							{user && user.address ? (
								<>
									<Option title label={val.title} icon={val.icon ? val.icon : false} />
									{val.childrens
										? val.childrens.map((children: any, childrenKey: number) => (
												<Tooltip title={children.label} placement="right-start" key={childrenKey}>
													<div
														className={`cursor-pointer flex items-center flex-col w-full gray-color ${verifyIsSelected(
															children,
														)}`}
														key={childrenKey}
														onClick={() => {
															redirectToLink(children)
														}}>
														<Option
															label={children.label}
															icon={children.icon ? children.icon : false}
															haveOptions={children.haveOptions}
															isOpenDropdown={isOpenDropdown}
														/>
														{children.options && isOpenDropdown
															? children.options.map((option: any, optionKey: number) => (
																	<div key={optionKey} className="">
																		<Option label={option.label} icon={option.icon ? option.icon : false} />
																	</div>
															  ))
															: ''}
													</div>
												</Tooltip>
										  ))
										: ''}
								</>
							) : (
								<>
									{!val.isLogin ? (
										<>
											{
												showFullMenu
												? <TextStyleCaption>Log in to your account to see more options</TextStyleCaption>
												: false
											}
											<Option title label={val.title} icon={val.icon ? val.icon : false} />
											{val.childrens
												? val.childrens.map((children: any, childrenKey: number) => (
														<Tooltip title={children.label} placement="right-start" key={childrenKey}>
															<div
																className={`cursor-pointer flex items-center flex-col w-full gray-color ${verifyIsSelected(
																	children,
																)}`}
																key={childrenKey}
																onClick={() => {
																	redirectToLink(children)
																}}>
																<Option
																	label={children.label}
																	icon={children.icon ? children.icon : false}
																	haveOptions={children.haveOptions}
																	isOpenDropdown={isOpenDropdown}
																/>
																{children.options && isOpenDropdown
																	? children.options.map((option: any, optionKey: number) => (
																			<div key={optionKey} className="">
																				<Option label={option.label} icon={option.icon ? option.icon : false} />
																			</div>
																	  ))
																	: ''}
															</div>
														</Tooltip>
												  ))
												: ''}
										</>
									) : (
										''
									)}
								</>
							)}
						</div>
					))}
				</div>
				<div className="cursor-pointer w-full flex flex-col items-center  rounded-[4px] font-mono gray-color">
					{profile && profile.name ? (
						<Tooltip title={profile.name} placement="top">
							<div
								className="flex items-center gap-2 w-full justify-center overflow-hidden max-w-[230px]"
								onClick={() => {
									nagiteToSettings()
								}}>
								{profile && profile.img && profile.img != 'null' ? (
									<img
										src={profile.img}
										alt="profile"
										className="min-w-[50px] w-[50px]"
										style={{
											width: '50px',
											height: '50px',
											objectFit: 'cover',
											borderRadius: '100%',
										}}
									/>
								) : (
									<p className="data__initial w-[30px] h-[30px] text-2xl ">
										{profile.name ? profile.name[0].toUpperCase() : ''}
									</p>
								)}
								{showFullMenu && (
									<div className="text-sm  truncate max-w-[60%] overflow-hidden">
										<span className="truncate">{profile.name}</span>
										<span className="truncate">
											<UserBalance />
										</span>
									</div>
								)}
							</div>
						</Tooltip>
					) : (
						''
					)}
					{showFullMenu && user && user.address && (
						<TextStyleCaption>
							Storage used:
							<span className="ml-1 font-mono"> {user.storage || 0} KB / {parseFloat(user.max_storage)} KB</span>
						</TextStyleCaption>
					)}
				</div>
			</aside>
			{openSocial && (
				<div>
					<ModalFollowersAndFollowing
						isTarget={true}
						targetTab={currentTab}
						setOpen={setOpenSocial}
						open={openSocial}
					/>
				</div>
			)}
		</div>
	)
}

export default Aside
