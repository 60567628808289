import React from 'react'
import { Link } from 'react-router-dom'

import KineContext from 'src/context/KineContext'

const CreateWallet: React.FC = () => {
	const { color } = React.useContext(KineContext)
	return (
		<div className="w-full flex flex items-center justify-center">
			<div className="ml-20 w-full items-center flex flex-col">
				<h1 className="text-bold text-3xl font-extrabold leading-9 tracking-tight ">
					Welcome Kinera Testenet Alfa 1.0!
				</h1>
				<p className="mb-2 mt-10 text-sm font-bold">
					To be able to use all the features on the platform, make sure you:
				</p>
				<ul>
					<li>
						<b>1 </b>Have a polkadot.js wallet or create a new one
					</li>
					<li>
						<b>2 </b>Have play tokens in the same wallet
					</li>
					<li>
						<b>3</b> Have or create a personal social profile
					</li>
				</ul>
				<div className="flex items-center flex-col gap-2 mt-5">
					<p className="font-bold ">Instructions:</p>
					<p className="font-bold">1. The first step is to create a wallet.</p>
				</div>
				<div className="w-[80%] flex items-center flex-col mt-5">
					<p>Learn how by watching the video.</p>
					<iframe width="660px" height="400" src="https://www.youtube.com/embed/sy7lvAqyzkY" title="tutorial"></iframe>
				</div>
				<div className="mt-10">
					Or follow the written instructions:
					<Link
						target="_blank"
						to="https://support.polkadot.network/support/solutions/articles/65000098878-how-to-create-a-dot-account"
						className={`underline text-white font-bold ml-2 p-1 bg-[${color.action}]`}
					>
						instructions
					</Link>
				</div>
				<div className="flex items-center flex-col gap-2 mt-10">
					<p className="font-bold"> 2. The Second Step is to Transfer Play Tokens to your Wallet</p>
					<p className="flex items-center gap-2">
						Go to{' '}
						<Link
							target="_blank"
							to="/reward"
							className={`underline text-white font-bold ml-1 p-1 bg-[${color.action}]`}
						>
							Claim tokens
						</Link>{' '}
						in the left menu bar and Collect your daily 2500 Play Tokens.
					</p>
				</div>
				<div className="flex items-center flex-col gap-2 mt-10">
					<p className="font-bold">3. Last step. How to Create a Social Profile.</p>
					<Link
						target="_blank"
						to="https://d-app.kinera.network/"
						className={`underline text-white font-bold ml-2 p-1 bg-[${color.action}]`}
					>
						Go to the Kinera App site
					</Link>
					<p>Make sure you are logged in with your wallet and that you have Play Tokens.</p>

					<p>Press Create Social Profile (at the top of the vertical menu) or go to</p>
					<Link
						target="_blank"
						to="/register-profile"
						className={`underline text-white font-bold ml-2 p-1 bg-[${color.action}]`}
					>
						Create Social Profile
					</Link>

					<p className="text-center">
						Introduce your name and Bio, upload a profile and cover photo if you like, and then press Create.
					</p>

					<p className="text-center mt-10">Now, you are ready to engage with the platform features.</p>
				</div>
			</div>
		</div>
	)
}

export default CreateWallet
