import React from 'react'

import CreateWallet from './components/CreateWallet'
import GetFounds from './components/GetFounds'
import CreateProfile from './components/CreateProfile'

interface Props {
	currentTab: any
}

const InitialHelp: React.FC<Props> = ({ currentTab }) => {
	return (
		<div className="h-full w-full mt-2 justify-between flex flex-col">
			<div className="w-full h-full flex flex-col items-center justify-center">
				<CreateWallet />
			</div>
		</div>
	)
}

export default InitialHelp
